import React from 'react';
import styled, {keyframes} from 'styled-components';
import Container from "../common/Container";
import {theme} from '../../core/theme';
import AnchorLink from "../common/AnchorLink";
import LogoText from "../common/LogoText";
import {fixedBackground, materialShadows, trianglesBackground} from "../../core/styles";
import NavLinks from "../theme/Header/NavLinks";

export default function Intro() {
	return (
		<Wrapper>
			<IntroWrapper>
				<Details as={Container}>
					<LogoText Component='h1' />
					<Profile>
						<ProfilePic />
						<ProfileText>
							<p className='largescreen'>Hi! I'm Alastair, a full-stack software developer</p>
							<p className='smallscreen'>Hi! I'm Alastair</p>
							<p className='smallscreen'>Full-stack software developer</p>
							<p>I build platforms and UIs for ethical web apps</p>
						</ProfileText>
					</Profile>
				</Details>
				<ActionButtons>
					<Links>
						<Nav />
					</Links>
					<ContinueArrow>
						<AnchorLink href="#motivation" />
					</ContinueArrow>
				</ActionButtons>
			</IntroWrapper>
		</Wrapper>
	);
}


const Wrapper = styled.div`
	// padding-bottom: 4rem;
	position: relative;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column; 
	align-items: center;
	
	${fixedBackground()}
	
`;

const IntroWrapper = styled.div`
	width: 100%;
	padding: 4rem 0 0 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	flex: 1 1 auto;
	margin: 0;
	z-index: 1;

	@media (max-width: 960px) {
		flex-direction: column;
	}
`;


const Details = styled.div`
	width: 100%;		
	height: calc(70vh - 4rem);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;	
	
  h1 {
		color: ${theme.hex.secondary.lighter};
		text-shadow: 1px 1px 3px ${theme.rgba.primary.darkest(0.5)};
		margin-bottom: 4vh;
		font-size: 7.5vw;	
		
		@media (max-width: 960px) {
			font-size: 10.5vw;
		}
		
		@media (min-width: 1900px) {
			font-size: 140px;
		}
		
		@media (max-width: 520px) {
		  margin-bottom: 1.33rem;
		}
	}	
`;

const Profile = styled.div`
	width: 49vw;
	display: flex;
	padding: 1rem;
	align-items: center;
	position: relative;
	
	.blob {
		display: none;
	}
	
	.blob:first-of-type {
		position: absolute;
		top: -40%;
		left: -25%;
		width: 150% !important;
		height: 200% !important;
		z-index: -1;
	}
	.blob:last-of-type {
		position: absolute;
		top: -25%;
		right: -25%;
		width: 400% !important;
		height: 200% !important;
		z-index: -1;
	}
	
			
	@media (max-width: 960px) {
		width: 68vw;
	}
	
	@media (min-width: 521px) {
    ${trianglesBackground}
    border-radius: 5px / 5px;
	}
	
	@media (max-width: 520px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 0;
	}
	
	@media (min-width: 1900px) {
		width: 920px;
	}
	
`;
const ProfilePic = styled.div`
	flex: 0 0 auto;
	height: 10vw;
	width: 10vw;
	background: url(../img/profile_pic.jpg);
	background-size: cover;
	background-position: center center;	
	margin-right: 1rem;
	border-radius: 2px;
	${materialShadows[1]}
	
	@media (max-width: 960px) {
		height: 15vw;
		width: 15vw;
	}
	
	@media (max-width: 640px) {
		height: 24vw;
		width: 24vw;
	}
	
	@media (max-width: 520px) {
	  width: 36vw;
		height: 36vw;
		margin: 0 0 1rem 0;		
	}
	

	@media (min-width: 1900px) {
		width: 190px;
		height: 190px;
	}
`;

const ProfileText = styled.div`
	flex: 1 1 auto;
	font-size: 1.6vw;
	font-weight: normal;
	color: ${theme.hex.secondary.lighter};
	color: ${theme.hex.secondary.darker};
	display: flex;
	flex-direction: column;
	justify-content: center;
	
	
	p {
		margin: 1vw;
		flex: 0 0 auto;		
	}
	
	p.smallscreen {
		display: none;
	}
	
	@media (max-width: 960px) {
		font-size: 2.2vw;
	}
	@media (max-width: 640px) {
		font-size: 16pt;
		p {
			margin: 1rem 0.33re;
		}
		p.smallscreen {
			display: block;
		}
		p.largescreen {
			display: none;
		}
	}
	
	@media (max-width: 520px) {
		font-size: 14pt;
		color: ${theme.rgba.secondary.lightest(0.8)};
		p {
		  text-align: center;
		  margin: 0 0 0.6rem 0;
		  padding: 0;
		}
	}
	
	@media (min-width: 1900px) {
		font-size: 30px;
	}
	
	
`;

const ActionButtons = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	margin-bottom: 1rem;
`;

const Nav = styled(NavLinks)`
	display: none;		
	
	@media (min-width: 961px) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 1rem 0;
	}
	
	
	a {				
		color: ${theme.hex.tertiary.darker};
		text-shadow: none;
		font-size: 1.33rem;
		margin: 0 1rem;
		cursor: pointer;				
		
		&:hover, &:active, &:focus {
			color: ${theme.hex.secondary.lightest};
		}							
	}
	
	.hireme {
		display: inline-block;
		padding: 0.66rem;
		margin-left: 1rem;		
	}

`;

const Links = styled.div`
	flex: 0 0 auto;
	width: 100%;
	background: ${theme.rgba.secondary.lightest(0.4)};
	${materialShadows[1]}
`;


const bounce = keyframes`
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	35% {
		transform: translateY(-10px);
	}
	65% {
		transform: translateY(-5px);
	}
`;


const ContinueArrow = styled.div`
	animation: 2s ${bounce};
	animation-iteration-count: 3;
	
	svg {
		color: #fafafa;
		font-size: 6rem;
		margin-top: 1rem;
	}
	
	a {
		// position: ;
		// bottom: 0;
		// left: 50%;
		// margin-left:-20px;
		display: block;
		margin: 1em 0;
		width: 40px;
		height: 40px;
		opacity: 0.7;
		cursor: pointer;
		background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
		background-size: contain;
		
		&:hover, &:active, &:focus {
			opacity: 0.9
		}
		
		@media (max-width: 960px) {
			margin-bottom: 8vh;
		}
	}
`;