import React, {useMemo, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import ContactForm from './ContactForm';
import Container from "../common/Container";
import LogoText from "../common/LogoText";
import {theme} from "../../core/theme";
import {backgroundCover, fixedBackground, fonts, trianglesBackground} from "../../core/styles";


export default function Contact() {
	return (
		<Wrapper id="contact">
			<ContactWrapper as={Container}>
				<Logo />
				<Details>
					<ContactForm />
				</Details>
				<DirectContact>
					<Email />
					<Phone />
				</DirectContact>
			</ContactWrapper>
		</Wrapper>
	);
}

const DirectContact = styled.div`
	margin-top: 3rem;
	font-size: 1.4rem;
	text-align: center;
	flex: 1 1 auto;
	// order: 2;
	
	&>div a {
		display: block;
		margin-bottom: 1rem;
		color: ${theme.hex.secondary.darker};
	 	cursor: pointer;

		&:hover, &:focus {
			color: ${theme.hex.secondary.main};
		}
		
		&:active {
			color: ${theme.hex.secondary.lighter};
		}
	}	
`;
const Email = styled((props) => {
	const _props = useMemo(() => {
		const a = 'al';
		const b = 'builtbybrayne.com';
		const link = `${a}+ws@${b}?subject=Question from the website&body=Hi Alastair,`
		const display = `${a}@${b}`;
		return {link, display};
	}, []);

	return (
		<div>
			<Obfuscator scheme='mailto' {..._props} {...props} />
		</div>
	);
})`		
`;

const Phone = styled((props) => {
	const _props = useMemo(() => {
		const a = '+44 ';
		const b = '(0) ';
		const c = '77792 ';
		const d = '66625';

		const link = [a, c, d].join('');
		const display = [a, b, c, d].join('');

		return {display, link};
	}, []);

	return (
		<div>
			<Obfuscator scheme='tel' {..._props} {...props} />
		</div>
	);
})`
	${fonts.lato}	
`;

function Obfuscator({scheme, display, link, ...props}) {
	const [href, setHref] = useState('#');
	const [content, setContent] = useState('');

	useEffect(() => {
		if (href === '#') {
			setTimeout(() => {
				setContent(display);
				setHref(`${scheme}:${link}`);
			}, 500);
		}
	}, [href, setContent, setHref, display, link, scheme]);

	return <a href={href} {...props}>{content}</a>;
}


const Wrapper = styled.div`
	// ${backgroundCover('../img/desk1.png')}
	min-height: 100vh;
	
	${fixedBackground(1, 4)}
`;

const ContactWrapper = styled.div`
	padding: 8rem 0 12rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	form {
		margin: 0;
	 	padding:0;
	}

	@media (max-width: 960px) {
		flex-direction: column;		
	}
	
	
`;

const Logo = styled(LogoText)`
	font-size: 7.5vw;
	flex: 0 0 auto;
	color: ${theme.hex.tertiary.lightest};
	text-shadow: 1px 1px 3px ${theme.rgba.primary.darkest(0.5)};
	margin-bottom: 4vh;	
	// order: 1;
	
	@media (max-width: 960px) {
		font-size: 10.5vw;
	}
	
	@media (min-width: 1900px) {
		font-size: 140px;
	}
`;

const Details = styled.div`
	flex: 0 0 auto;	
	width: 42rem;
	padding: 1.66rem;
	background: ${theme.rgba.secondary.lightest(0.65)};
	${trianglesBackground}
	// order: 3;

	@media (max-width: 960px) {
		width: calc(100% - 1.66rem - 1.66rem);
		// order: 1;
	}
	
	@media (max-width: 420px) {
		width: calc(100% - 2rem);
		padding: 1rem;
	}

	h1 {
		margin-bottom: 2rem;
		font-size: 26pt;
		color: #212121;
	}

	p {
		margin-bottom: 2.5rem;
		font-size: 20pt;
		font-weight: normal;
		line-height: 1.3;
		color: #707070;
	}
`;
