import React from 'react';
import styled from 'styled-components';
import {backgroundCover, fonts, materialShadows, standardHPadding} from "../../core/styles";
import {colours} from "../../core/colours";
import Container from "../common/Container";

const experience = [{
	title: 'SmarterMicrogrid',
	img: 'smc.png',
	description: 'Smart platform for small-scale, distributed renewable energy microgrids',
	role: 'Full-stack developer; system architecture, platform, and UI',
	notes: 'React, NodeJs, IoT, AWS, OS, DevOps ... everything, really!'
}, {
	title: 'MixRadio',
	img: 'mixradio.png',
	description: 'Streaming music service that brings you free, personalized music',
	role: 'Senior React Developer',
	notes: 'React, React, React, and more React'
}, {
	title: 'Brightpearl',
	img: 'brightpearl.jpg',
	description: '"The omnichannel retail management system that puts your orders, inventory, financials, POS and CRM in one place"',
	role: 'Senior Developer',
	notes: 'Java & PHP enterprise-scale web platform. Lots of legacy debugging!'
}, {
	title: 'Transitions',
	img: 'transitions.jpg',
	description: 'Protect your eyes with light adaptive lenses',
	role: 'HTML5 App Developer',
	notes: 'B2B HTML5 training apps, delivered via offline-resilient iPads'
}, {
	title: 'Diesel',
	img: 'diesel.jpg',
	description: 'Award-winning "Days To Live" marketing campaign',
	role: 'HTML5 Developer',
	notes: 'White-knight contract to push campaign out in time. Lots of audio and time-line challenges'
}, {
	title: 'Click',
	img: 'click.jpg',
	description: 'Digital consultancy and delivery partner',
	role: 'Good Old-Fashioned Web Developer',
	notes: 'Mostly Wordpress and JQuery'
}, {
	title: 'Tactix4',
	img: 'tactix4.jpg',
	description: 'Building Better Healthcare',
	role: 'Web App Developer',
	notes: 'Straight up UI app development'
}, {
	title: 'Taptu',
	img: 'taptu.jpg',
	description: 'Social News Reader and Search, for Mobile Phones (pre-iPhone!)',
	role: 'Java UI Developer',
	notes: 'Did I mention pre-iPhone? Boy, was that tough'
}];


export default function Experience() {
	return (
		<Wrapper id='experience'>
			<ExperienceWrapper as={Container}>
				<h2>Some Backstory</h2>
				<TLDR><span className="tldr">TL;DR:</span> <span>Full-stack web application development<br />Currently <i>React</i>, <i>NodeJs</i>, <i>AWS</i>, and <i>IoT</i></span></TLDR>
				<Row>
					{experience.map(details => <Item key={details.title} {...details} />)}
				</Row>
			</ExperienceWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	align-items: center;	
`;

const ExperienceWrapper = styled.div`
	padding: 4rem 0;	
	
	@media (max-width: 420px) {
		padding: 4rem 0rem;
	}
	
	h2 {
		${standardHPadding}
	}
`;

const Row = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	${materialShadows[3]}
	
	@media (max-width: 960px) {
		grid-template-columns: repeat(2, 1fr);
	}		
	
	@media (max-width: 420px) {
		width: calc(100% - 2rem);
		margin-left: 1rem;
		grid-template-columns: repeat(1, 1fr);
	}		
`;

const Item = styled(({title, description, role, notes, ...props}) => {
	return (
		<div {...props}>
			<div className='text'>.</div>
			<div className='inner'>
				<div className='popout'>
					<ItemTitle>{title}</ItemTitle>
					<ItemDescription>{description}</ItemDescription>
					<ItemRole>{role}</ItemRole>
					<ItemNotes>{notes}</ItemNotes>
				</div>
			</div>
		</div>
	);
})`
	position: relative;
	z-index: 1;
	
	&:hover, &:active, &:focus {
		z-index: 2
	}
	
	.inner {
		${({img}) => backgroundCover(`../img/${img}`)}
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 250ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
		cursor: pointer;
		
		.popout {
			display: none;
			position: absolute;
			opacity: 0;
			top: 100%;
			left: 0;
			background: white;
			transition: all 450ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
			padding: 1rem;
			width: calc(100% - 2rem);
		}
		
		&:hover, &:active, &:focus  {
			${materialShadows[5]}
			width: calc(100% + 1rem);
			height: calc(100% + 1rem);
			top: -0.5rem;
			left: -0.5rem;
			
			.popout {
				display: block;
				${materialShadows[5]}
				opacity: 1;
			}
		}
		
	}
	.text {
		padding-top: 56.25%;
		opacity: 0;
	}
`;

const ItemTitle = styled.h3`
`;
const ItemDescription = styled.p`
`;
const ItemRole = styled.p`
	${fonts.lato}
`;
const ItemNotes = styled.p`
	font-style: italic;
	font-size: 0.9rem;
	color: ${colours.grey[500]}
`;


const TLDR = styled.div`
	${standardHPadding}
	margin-bottom: 2rem;
	color: ${colours.grey[600]};
	display: flex;
	align-items: top;
	justify-content: center;
	line-height: 1.66rem;
	
	@media (max-width: 420px) {
		flex-direction: column;		
	}
	
	
	.tldr {		
		${fonts.lato}
		font-weight: bold;
		margin-right: 0.66rem;
	}
	
	i {
		${fonts.lato}
		font-style: normal;
	}
`;