import React from 'react';
import styled from 'styled-components';
import LogoText from "../common/LogoText";
import Container from "../common/Container";
import {backgroundCover, materialShadows, standardHPadding} from "../../core/styles";


export default function Motivation() {
	return (
		<Wrapper id='motivation'>
			<MotivationWrapper as={Container}>
				{/* <div className='flex-container-col page'> */}
				{/* <h1>We build apps for social positive change.</h1> */}
				{/* <p>Our world, our society, and the individuals within them need support to flourish and become the best they can be.</p> */}
				{/* <p>There are real, profound challenges we must face to achieve this.</p> */}
				{/* <p>Apps can make a difference.</p> */}
				{/* </div> */}

				<h2>My Motivation</h2>

				<Row>
					<Icon src='../img/mentalhealth_themed.svg' />
					<Text>
						<h2>Mental Wellbeing</h2>
						<p>We need support to thrive among the explosive changes transforming our societies and cultures today.</p>
						<p>Apps can provide the tools, encouragement and social connections to sustain these efforts.</p>
					</Text>
				</Row>

				<Row>
					<Icon src='../img/climatechange_themed.svg' />
					<Text>
						<h2>Climate Change</h2>
						<p>We need to go beyond the status quo and prevailing paradigms to engage with the ecological challenges we face.</p>
						<p>Apps can help us make conscious personal choices and collectively magnify our power to effect change.</p>
					</Text>
				</Row>

				<Row>
					<Icon src='../img/social_theme.svg' />
					<Text>
						<h2>Socially Conscious Technology</h2>
						<p>We can choose to build technologies which promote positive impacts - globally and locally.</p>
						<p>Apps can gather and display the information needed to create action and connection.</p>
					</Text>
				</Row>

				<Row>
					<Icon src='../img/socialapp_themed.svg' />
					<Text>
						<h1>This is <Logo /><br />Building apps for positive social change</h1>
						<p>Let's work together to create tools to truly make our world a better place.</p>
						<p>Get in touch if you want to create future-positive apps in the areas of mental wellbeing, climate change or socially conscious technology.</p>
					</Text>
				</Row>
			</MotivationWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	min-height: 100vh;
`;
const MotivationWrapper = styled.div`
	padding: 3rem 0;
	${standardHPadding}
	
	& > :first-child {
		margin-top: 3rem;
	}
	
	& > :last-child {
		margin-bottom: 3rem;
	}
	
  
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	margin: 2rem 0;
	min-height: 20vw;
	
	&:nth-of-type(2n) {
		flex-direction: row-reverse;
	}
	
	@media (max-width: 640px) {
		flex-direction: column;
		align-items: center;
		
		&:nth-of-type(2n) {
			flex-direction: column;
		}
	}
	
	@media (min-width: 1600px) {
		max-height: calc(100vh - 12rem);
	}		
`;

const Img = styled.div`
	display: none;
	position: relative;	
	flex: 1 1 50%;
	margin: 0 1rem;
	${materialShadows[2]}
	${({src, pos}) => backgroundCover(src, pos, 0.9)}
	
	&:before {
		opacity: 0.9;
	}	
`;

const Icon = styled(({src, ...props}) => {
	return (
		<div {...props}>
			<img src={src} alt='' />
		</div>
	);
})`
	// display: none;
	flex: 1 1 50%;
	padding: 2rem 0;
	
	img {
		margin-bottom: 0;
	}
	
	@media (max-width: 640px) {
		flex: 0 0 auto;
		padding: 1rem;
		width: 400px;
		max-width: 85%;			
	}
`;

const Text = styled.div`
	flex: 1 1 50%;
	align-content: center;
	display: flex;
	flex-direction: column;
	justify-content: center; 
	
	&>* {
		flex: 0 0 auto;		
	}
	
	@media (min-width: 961px) {
		margin: 0 1rem;
		font-size: 1.2rem;
	}
`;

const Logo = styled(LogoText)`
	@media (max-width: 420px) {
		display: inline-block;
		font-size: 10vw;
		padding-bottom: 1rem;
	}
`;