import React from 'react';
import styled from 'styled-components';
import Container from "../common/Container";
import Card from "../common/Card";
import {fixedBackground, fonts, materialShadows, section, standardHPadding} from "../../core/styles";
import {theme} from "../../core/theme";
import Button from "../common/Button";
import BkgImg from "../common/BkgImg";
import {colours} from "../../core/colours";
import {useToggleState} from "../../core/hooks";
import HireMe from "../common/hireme";

const SMC = styled((props) => {
	const title = <span><span style={{color: '#ff5b2d'}}>Smarter</span>Microgrid</span>;
	const action = ['Visit', 'http://smartermicrogrid.com', true];
	const details = {
		position: 'Founding developer',
		responsibilities: 'Platform, UI',
		tech: 'IoT, React, NodeJs, AWS'
	};
	return (
		<Project {...props} {...{title, details, action}}>
			<BkgImg src='../img/turbines.jpeg' className='img' />
			<p>A smart platform for small-scale, distributed renewable energy microgrids</p>
		</Project>
	);
})`
	h4 {
		font-family: Arial, sans-serif;			
	}
	
	.img:before {
		background-position: top center;
	}	
`;


const DoBuddy = styled((props) => {
	const details = {
		position: 'Founder',
		responsibilities: `Product design, App Development`
	};
	const action = ['Visit', 'http://dobuddy.app', true];
	return (
		<Project {...props} title='DoBuddy' {...{details, action}}>
			<BkgImg src='../img/unpottedplant.jpeg' className='img' />
			<p>The todo list with your wellbeing at heart</p>
		</Project>
	);
})`
	h4 {
		font-family: Berkshire Swash, Serif;
	}
	
	.img:before {
		background-position: center 25%;
	}
`;

const YourProject = styled(({className, ...props}) => {
	return (
		<div className={`yourproject ${className}`} {...props}>
			<p>Have you got a business or project addressing wellbeing or climate change?</p>
			<p>Do you need some developer firepower?</p>
			<HireMeButton />
			<p className='specifics'>
				<span className="ideal">ideal stack:</span><br /><i>React</i>, <i>NodeJs</i>, <i>AWS</i>, <i>IoT</i>
			</p>
		</div>
	);
})`
	padding: 2rem;
	margin-top: 2rem;
	color: ${theme.hex.secondary.darkest};
	// text-shadow: 0px 0px 24px ${theme.rgba.secondary.lightest(0.3)}, 0px 0px 12px ${theme.rgba.secondary.lightest(0.2)};
	text-align: center;
	
	.hireme {
		margin-top: 1.66rem;
	}
	
	p {
		margin-bottom: 0.66rem;
	}
	
	.specifics {
		margin-top: 3rem;
		font-size: 0.9rem;
		color: ${theme.rgba.tertiary.darker(0.8)};
		.ideal {
			font-variant: small-caps;
		}
		i {
			${fonts.lato}
			font-style: normal;
		}
	}
	
`;

const HireMeButton = styled(HireMe)`
	margin-top: 1.66rem;
`;


export default function Projects() {
	return (
		<Wrapper id="projects">
			<ProjectsWrapper as={Container}>
				<h2>Projects</h2>
				<Grid>
					<SMC />
					<DoBuddy />
					<YourProject />
				</Grid>
			</ProjectsWrapper>
		</Wrapper>
	);
}


const Project = styled(({className, title, children, details = {}, action}) => {
	return (
		<Card className={className}>
			<h4>{title}</h4>
			<div className='content'>
				{children}
				{action && (
					<div className='center'>
						<Button tertiary className='button' as='a' href={action[1]} {...(action[2] ? {target: '_blank', rel: "noopener noreferrer"} : {})}>{action[0]}</Button>
					</div>
				)}
			</div>
			{Object.keys(details).length > 0 && <ProjectDetails className='details' {...details} />}
		</Card>
	);
})`
	overflow: hidden;
	background: rgba(255, 255, 255, 0.9);
	padding: 0;
	${materialShadows[2]}
	display: flex;
	flex-direction: column;
	align-items: center;
	
	&>*, .content>* {
		margin-left: 2rem;
		margin-right: 2rem;
	}
	
	.content {
		width: 100%;
		flex: 1 1 auto;
		
		p {
			padding-top: 0.66rem;
			
			@media (min-width: 961px) {
				min-height: 4rem;
			}
			@media (min-width: 1501px) {
				min-height: 2rem;
			}
		}

	}
	
	.center {
		text-align: center;
	}
	.button {
		display: inline-block;
		margin-bottom: 2rem;			
	}

	h4, .block {
		margin:0;
		padding: 1rem 2rem;
		box-sizing: border-box;
		width: 100%;		
	}
	
	.img {
		width: 100%;
		height: 10rem;
		margin: 0 0 1.33rem 0;
		
		&:before {
			border-top: 0.33rem solid ${theme.rgba.tertiary.dark(1)};
			border-bottom: 0.33rem solid ${theme.rgba.tertiary.dark(1)};
			box-sizing: content-box;
			opacity: 0.92;
		}				
	}
	
	.details {
		
	}
	
	h4 {
		color: #212121;
	}

	p {
		color: #707070;
	}
`;


const ProjectDetails = styled(({className, client, position, responsibilities, tech}) => {
	const [show, toggleShow] = useToggleState();

	return (
		<div className={className}>
			<div className={`trigger ${show ? 'less' : 'more'}`} onClick={toggleShow}>
				{show ? 'Less Details' : 'More Details'}
			</div>
			{show && (
				<table>
					{getDetail('Client', client)}
					{getDetail('Position', position)}
					{getDetail('Responsibilities', responsibilities)}
					{getDetail('Tech', tech)}
				</table>
			)}
		</div>
	);
})`
	width: 100%;
	
	.trigger {
		cursor: pointer;
		color: ${colours.grey[500]};
		font-size: 0.8rem;
		width: 100%;
		text-align: center;
		padding: 0.33rem;
		
		&.more {
			border-top: 1px solid ${colours.grey[300]};
		}
		
		&.less {
			border-bottom: 1px solid ${colours.grey[300]};
		}
	}
	
	table {
		padding: 0;
		margin: 0;
		th, td {
			padding: 0.33rem 1rem;
			font-size: 0.8rem;
		}
		th {
			${fonts.lato}
			font-weight: bold;
			color: ${colours.grey[600]};
			text-align: right;
		}
	}
	
`;

function getDetail(key, value) {
	return value ? (
		<tr>
			<th>{key}</th>
			<td>{value}</td>
		</tr>
	) : <React.Fragment />;
}


const Wrapper = styled.div`
	min-height: 100vh;
	display: flex;
	align-items: center;	
	${fixedBackground(3, 6)}
`;

const ProjectsWrapper = styled.div`
	${standardHPadding}	
	padding-top: 4rem;
	padding-bottom: 6rem;
	
	h2 {
		color: ${theme.hex.secondary.lightest};
	}
`;

const Grid = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 8fr;
	gap: 1.2rem 1.2rem;

	@media (max-width: 960px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (max-width: 680px) {
		grid-template-columns: 1fr;
	}
`;

