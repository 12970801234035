import React from 'react';
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Intro from "./Intro";
import Projects from "./Projects";
import Contact from "./Contact";
import Motivation from "./Motivation";
import Experience from './Experience';
import Header from "../theme/Header";

export default function Index() {
	return (
		<Layout>
			<SEO />
			<Header />
			<Intro />
			<Motivation />
			<Projects />
			<Experience />
			<Contact />
		</Layout>
	);
}